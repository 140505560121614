import React, {useState, useContext} from 'react';
import './Contact.css';
import MyImage from '../Base/MyImage';
import InfoButton from '../Base/InfoButton'
//import anime from 'animejs';

import startBG from './../minimalPictures/pcb1.jpg'
import projectPic from './../minimalPictures/pic2.jpg'
import desktopPic from './../minimalPictures/desktop.jpg'
import { langContext } from '../Base/lang';

function Contact() {

    const {curLang} = useContext(langContext);
    const [isLoading, setLoading] = useState("PageFade");
    const [startTitleClass, setStartTitleClass] = useState("startTitle");

    

    React.useEffect(() => {
        /*anime({
            targets: PageFadeDiv.current,
            opacity: [1.0, 0.0],
            duration: 500,
            easing: 'cubicBezier(.5,.01,.18,.98)',
          });*/
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;

          var timeoutid = setTimeout(() => {
            setLoading("PageFadeOut");
          }, 200);

          return () => {
            clearTimeout(timeoutid);
          }
    }, []);

    return (
        <React.Fragment>
            <div className={isLoading}></div>
            <div className="container-1">
              <div className="containerContent">
                <div className="contact-Cont">
                  <div className="textI">
                    <p className="text textOne">{curLang["Contact-NoForm"]}</p>
                    <a className="text textMail blueText" href={"mailto:" + curLang["Contact-Mail"]}>{curLang["Contact-Mail-Text"]}</a>
                  </div>
                </div>
              </div>
            </div>
        </React.Fragment>
    );
}

export default Contact;
