import React, { useState, useMemo } from 'react';

import './Base.css';
import './colors.css';

import Navbar from './Navbar';
import Home from '../Site/Home';
import Contact from '../Site/Contact';
import Projects from '../Site/Projects';

import { /*MyImage,*/ getImagesTotal, getImagesLoaded } from './MyImage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import anime from 'animejs';

import { langContext, returnLang } from './lang.js';

function Base() {
    const [isPreload, setPreload] = useState(true);
    const [curLang, setLang] = useState(returnLang('de'));
    const providerLang = useMemo(() => ({ curLang, setLang }), [curLang, setLang]);

    React.useEffect(() => {
        window.addEventListener('beforeunload', function (event) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        });

        const ele = document.getElementById('loadingScreenBlack');
        if (ele) {
            function doneLoading() {
                //preLoadDiv.current.remove();
                setPreload(false);

                anime({
                    targets: '#loadingScreenBlack',

                    opacity: 0.0,
                    duration: 400,
                    easing: 'cubicBezier(.5,.01,.18,.98)',
                    complete: () => {
                        ele.style.pointerEvents = 'none';
                    },
                });
            }

            function testIfAllPicturesWereLoaded() {
                if (getImagesLoaded() >= getImagesTotal()) {
                    doneLoading();
                } else {
                    setTimeout(testIfAllPicturesWereLoaded, 100);
                }
            }

            testIfAllPicturesWereLoaded();
        }
    }, []);

    return (
        <Router>
            <langContext.Provider value={providerLang}>
                <div>
                    <Navbar />
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/projects" component={Projects} />
                        <Route path="/projekte" component={Projects} />
                        <Route path="/about" exact component={Home} />
                        <Route path="/über" exact component={Home} />
                        <Route path="/contact" exact component={Contact} />
                        <Route path="/kontakt" exact component={Contact} />
                    </Switch>
                    {isPreload ? (
                        <div
                            style={{
                                display: 'none',
                            }}
                        ></div>
                    ) : (
                        ''
                    )}
                </div>
            </langContext.Provider>
        </Router>
    );
}

export default Base;
