import React, { useRef, useState, useContext } from 'react';
import './Projects.css';
import MyImage from '../Base/MyImage';
//import anime from 'animejs';

import { langContext } from '../Base/lang';
import startBG from './../minimalPictures/pic4.jpg';
import ProgressBar from '../Base/ProgressBar';
import Project from './Project';

var _projects = {};

function Projects(props) {
    const { curLang } = useContext(langContext);
    let PageFadeDiv = useRef(null);
    const [isLoading, setLoading] = useState('PageFade');
    const [projects, setProjects] = useState({});

    let languages = [
        { name: 'Java', percent: 0.75 },
        { name: 'C++', percent: 0.55 },
        { name: 'C', percent: 0.85 },
        { name: 'Python', percent: 0.7 },
        { name: 'Cuda', percent: 0.025 },
        { name: 'Lua', percent: 0.6 },
        { name: 'PHP', percent: 0.5 },
        { name: 'JavaScript', percent: 0.875 },
        { name: 'HTML & CSS', percent: 0.65 },
    ];

    React.useEffect(() => {
        /*anime({
            targets: PageFadeDiv.current,
            opacity: [1.0, 0.0],
            duration: 500,
            easing: 'cubicBezier(.5,.01,.18,.98)',
          });*/
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        var timeoutid = setTimeout(() => {
            //setLoading('PageFadeOut');
        }, 200);

        const xhttp = new XMLHttpRequest();
        xhttp.onload = function () {
            _projects = JSON.parse(this.responseText);
            setProjects(_projects);
            setLoading('PageFadeOut');
        };
        xhttp.open('GET', 'https://resources.umbach.dev/projects', true);
        xhttp.send();

        return () => {
            clearTimeout(timeoutid);
        };
    }, []);

    React.useEffect(() => {
        var isValidProjectOpen = false;

        for (const key in projects) {
            if (Object.hasOwnProperty.call(projects, key)) {
                const element = projects[key];

                if ('/projects/' + element.url === props.location.pathname) {
                    document.body.style.overflow = 'hidden';

                    isValidProjectOpen = true;
                    break;
                }
            }
        }
        if (isValidProjectOpen === false) {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    });

    return (
        <React.Fragment>
            <div className={isLoading} ref={PageFadeDiv}></div>
            <MyImage className="startImage" srcMinimal={startBG} srcHD="pic3.jpg" w={50000} h={50000} autoscaleMargin={0} />
            <div className="container-1">
                <div className="containerContent">
                    <div className="languages-Cont">
                        <div>
                            <p className="welcomeText">{curLang['Projects-Languages']}</p>
                        </div>
                        <div className="textI">
                            <p className="text textTwo">{curLang['Projects-Languages-Text']}</p>
                            {languages.map((item) => {
                                return (
                                    <div key={'languageItem' + item.name} className="languageItem">
                                        <p>{item.name}</p>
                                        <ProgressBar width="300px" height={5} percent={item.percent} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-2">
                <div className="containerContent">
                    <div className="welcome">
                        <div>
                            <p className="welcomeText">{curLang['Home-infoButton-projects']}</p>
                            {Object.keys(projects).map((key) => (
                                <Project key={'projectObj' + key} value={projects[key]} current={props.location} />
                            ))}
                        </div>
                        <div className="buttonArea"></div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Projects;
