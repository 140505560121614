import React, { useState, useContext } from 'react';
import './Home.css';
import MyImage from '../Base/MyImage';
import InfoButton from '../Base/InfoButton';
//import anime from 'animejs';

import startBG from './../minimalPictures/pcb1.jpg';
import projectPic from './../minimalPictures/pic2.jpg';
import desktopPic from './../minimalPictures/desktop.jpg';
import { langContext } from '../Base/lang';

function Home() {
    const { curLang } = useContext(langContext);
    const [isLoading, setLoading] = useState('PageFade');
    const [startTitleClass, setStartTitleClass] = useState('startTitle');

    React.useEffect(() => {
        /*anime({
            targets: PageFadeDiv.current,
            opacity: [1.0, 0.0],
            duration: 500,
            easing: 'cubicBezier(.5,.01,.18,.98)',
          });*/
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        var timeoutid = setTimeout(() => {
            setLoading('PageFadeOut');
        }, 200);

        return () => {
            clearTimeout(timeoutid);
        };
    }, []);

    return (
        <React.Fragment>
            <div className={isLoading}></div>
            <div className={startTitleClass}>
                <div>
                    <p className="TitleText">
                        <strong>Jan Umbach</strong>
                    </p>
                    <p>{curLang['Home-description']}</p>
                </div>
            </div>
            <MyImage className="startImage" srcMinimal={startBG} srcHD="pcb1.jpg" w={50000} h={50000} autoscaleMargin={0} />
            <div className="container-1">
                <div className="containerContent">
                    <div className="welcome">
                        <div>
                            <p className="welcomeText">{curLang['Home-welcome']}</p>
                        </div>
                        <div className="textI">
                            <p className="text textOne blueText">
                                <strong>{curLang['Home-introText1']}</strong>
                            </p>
                            <br />
                            <p className="text textTwo">{curLang['Home-introText2']}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-2">
                <div className="containerContent">
                    <div className="welcome">
                        <div>
                            <p className="welcomeText">{curLang['Home-myWork']}</p>
                        </div>
                        <div className="buttonArea">
                            <InfoButton text={curLang['Home-infoButton-projects']} url="/projects" srcMinimal={projectPic} srcHD="pic1.jpg" />
                            <InfoButton text={curLang['Home-infoButton-equipment']} url="/equipment" srcMinimal={desktopPic} srcHD="desktop.jpg" />
                            {/*<InfoButton text={curLang["Home-infoButton-school"]} url="/#school" srcMinimal={projectPic} srcHD="pic1.jpg"/>*/}
                            <InfoButton text={curLang['Home-infoButton-contact']} url="/contact" srcMinimal={projectPic} srcHD="pic1.jpg" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Home;
