import React, { useRef, useState, useContext } from 'react';
import './Project.css';
import MyImage from '../Base/MyImage';
import InfoButton from '../Base/InfoButton';
//import anime from 'animejs';
import { langContext } from '../Base/lang';
import ProgressBar from '../Base/ProgressBar';

import { Link } from 'react-router-dom';

const imageServer = 'https://resources.umbach.dev/';

function Project(props) {
    const { curLang } = useContext(langContext);

    const [isMobile, setMobile] = React.useState(false);

    const [deepData, setDeepData] = React.useState(null);

    const [carouselPos, setCarouselPos] = React.useState(0);

    const fullscreenProject = '/projects/' + props.value.url === props.current.pathname;
    const [fullscreenProjectDelayed, setFullscreenProjectDelayed] = React.useState(false);
    const [fullscreenProjectDelayedTimer, setFullscreenProjectDelayedTimer] = React.useState(null);

    React.useEffect(() => {
        if (fullscreenProjectDelayed === false) {
            if (fullscreenProject === true) {
                setFullscreenProjectDelayed(true);
                const xhttp = new XMLHttpRequest();
                xhttp.onload = function () {
                    setDeepData(JSON.parse(this.responseText));
                    console.log(JSON.parse(this.responseText));
                };
                xhttp.open('GET', 'https://resources.umbach.dev/project/' + props.value.url, true);
                xhttp.send();
            }
        } else {
            if (fullscreenProject === false) {
                if (fullscreenProjectDelayedTimer === null) {
                    let timer = setTimeout(() => {
                        setFullscreenProjectDelayedTimer('delayed');
                    }, 2000);

                    setFullscreenProjectDelayedTimer('running');
                }
            }
        }
        if (fullscreenProjectDelayedTimer === 'delayed') {
            if (fullscreenProject === false) {
                setFullscreenProjectDelayed(false);
            }
            setFullscreenProjectDelayedTimer(null);
        }
    });

    React.useEffect(() => {
        function handleResize() {
            setMobile(window.innerWidth < 600);
        }
        window.addEventListener('resize', handleResize);

        return (_) => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <Link className={'ProjectBox ' + (fullscreenProject ? 'ProjectBoxHidden' : '')} to={'/projects/' + props.value.url}>
                <div className="ProjectBoxImageContainer">
                    <MyImage className="ProjectBoxImage disableHighlight" srcMinimal={props.value.thumbnail[0]} srcHD={imageServer + props.value.thumbnail[1]} w={300} h={180} />
                </div>
                <div className="ProjectBoxTitle">
                    <h3>{props.value.name[curLang['lang']]}</h3>

                    <p className="ProjectBoxDesc">{props.value.desc[curLang['lang']]}</p>
                </div>
            </Link>
            <div className={fullscreenProject ? 'ProjectMBackground' : 'ProjectMBackgroundHidden'}>
                {fullscreenProjectDelayed ? (
                    <div className="ProjectM">
                        <MyImage className="startImage" srcMinimal={props.value.titleImage[0]} srcHD={imageServer + props.value.titleImage[1]} w={50000} h={50000} autoscaleMargin={0} />
                        {deepData === null ? (
                            <div className="MyImageLoadingIcon" style={{ width: 40, height: 40, left: '50%', top: 'calc(100% + 140px)' }}>
                                <div className="MyImage_loadingCircle"></div>
                            </div>
                        ) : (
                            <div className="ImagesContainer">
                                <p className="welcomeText">{curLang['Project-Images']}</p>
                                <div className="ImageDiv">
                                    {Object.keys(deepData.deep).map((key) => (
                                        //carouselPos

                                        <div className="ImageDiv2" key={'projectCImg' + key}>
                                            <div>
                                                {deepData.deep[key].format === 'mp4' ? (
                                                    <video className="ProjectVideo" controls>
                                                        <source src={imageServer + 'project/' + deepData.url + '/' + key + '.mp4'} type="video/mp4" />
                                                    </video>
                                                ) : (
                                                    <MyImage
                                                        className="ProjectImage"
                                                        srcMinimal={deepData.deep[key].preview}
                                                        srcHD={imageServer + 'project/' + deepData.url + '/' + key + '.' + deepData.deep[key].format}
                                                        w={400}
                                                        forceLoad
                                                        autoscaleMargin={60}
                                                        onClick={() => {
                                                            let url = imageServer + 'project/' + deepData.url + '/' + key + '.' + deepData.deep[key].format;
                                                            var _window = window.open(url, '_blank');
                                                            _window.focus();
                                                        }}
                                                    />
                                                )}
                                                {deepData.deep[key].data !== null && deepData.deep[key].data.date !== undefined ? (
                                                    <p className="ProjectImageDate">{deepData.deep[key].data.date}</p>
                                                ) : null}
                                            </div>
                                            {deepData.deep[key].data !== null && deepData.deep[key].data.desc !== undefined ? (
                                                <p className="ProjectImageText">{deepData.deep[key].data.desc[curLang['lang']]}</p>
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}{' '}
            </div>
        </React.Fragment>
    );
}

export default Project;
