import React, {useState, useRef} from 'react';
import anime from 'animejs';
import MyImage from '../Base/MyImage';
import './InfoButton.css';
import {Link} from 'react-router-dom';


function InfoButton(props) {

  //const [isNavbarOpen, setNavbarOpen] = useState(false);
  //const [isDarkmode, setDarkmode] = useState(true);
  
  //let isNavbarShrinkedBool = useRef(false);

  React.useEffect(() => {

  }, []);

  return (
    <Link className="disableHighlight infoButton" to={props.url}>
        <MyImage className="startImage" srcMinimal={props.srcMinimal} srcHD={props.srcHD} w={300} h={168.75} autoscaleMargin={60}/>
        <p>{props.text}</p>
    </Link>
  );
}

export default InfoButton;
