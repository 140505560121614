import { createContext } from 'react';

export const langContext = createContext(null);

export function returnLang(code) {
    let curLang = {};

    curLang['lang'] = 0;
    curLang['Navbar_about'] = 'Über';
    curLang['Navbar_projects'] = 'Projekte';
    curLang['Navbar_contact'] = 'Kontakt';

    curLang['Home-description'] = 'Hobbyist in Entwicklung, Elektronik & Robotik';
    curLang['Home-welcome'] = 'Willkommen';
    curLang['Home-introText1'] = 'Ich bin Jan!';
    curLang['Home-introText2'] =
        'Hier erfahren Sie alles über mich und meine Projekte. Ich investiere sehr viel Zeit in Software und Hardware. Dabei wird mein Wissen von Projekt zu Projekt immer größer. Im Moment ist es neben der Schule nur ein Hobby, aber ich habe vor in diesem Bereich beruflich tätig zu werden, weil es mir viel spaß macht Dinge zu bauen und zu automatisieren. Ich habe diese Seite erschaffen, um meine Erfahrungen und Interessen zu teilen und mit gleichgesinnten in Kontakt zu treten.';
    curLang['Home-myWork'] = 'Infos';

    curLang['Home-infoButton-school'] = 'Karriere';
    curLang['Home-infoButton-equipment'] = 'Meine Ausrüstung';
    curLang['Home-infoButton-projects'] = 'Meine Projekte';
    curLang['Home-infoButton-contact'] = 'Kontaktiere mich';

    curLang['Contact-NoForm'] = 'Zurzeit habe ich noch kein Kontaktformular implementiert, aber Sie können gerne bei Bedarf eine E-Mail an mich senden:';
    curLang['Contact-Mail'] = 'jan@umbach.dev';
    curLang['Contact-Mail-Text'] = 'Jan Umbach, ' + curLang['Contact-Mail'];

    curLang['Projects-Languages'] = 'Sprachen';
    curLang['Projects-Languages-Text'] =
        'Hier sehen Sie all die Sprachen, die ich mir bei meinen Projekten angeeignet habe. Die Verteilung dient nur zur Veranschaulichung, wie vertraut ich mit der jeweiligen Sprache im Verhältnis zu anderen Sprachen bin.';
    curLang['Project-Images'] = 'Bilder';

    if (code === 'en') {
        curLang['lang'] = 1;
        curLang['Navbar_about'] = 'About';
        curLang['Navbar_projects'] = 'Projects';
        curLang['Navbar_contact'] = 'Contact';

        curLang['Home-description'] = 'Hobbyist in development, electronics & robotics';
        curLang['Home-welcome'] = 'Welcome';
        curLang['Home-introText1'] = "I'm Jan!";
        curLang['Home-introText2'] =
            "Here you can find out everything about me and my projects. I invest a lot of time in software and hardware. My knowledge grows from project to project. At the moment it's just a hobby besides school, but I plan to work in this area because I really enjoy building and automating things. I created this site to share my experiences and interests and to get in touch with like-minded people.";
        curLang['Home-myWork'] = 'Infos';

        curLang['Home-infoButton-school'] = 'Career';
        curLang['Home-infoButton-equipment'] = 'My equipment';
        curLang['Home-infoButton-projects'] = 'My projects';
        curLang['Home-infoButton-contact'] = 'Contact me';

        curLang['Contact-NoForm'] = "At the moment I haven't implemented a contact form, but you're welcome to email me if necessary:";

        curLang['Projects-Languages'] = 'Languages';
        curLang['Projects-Languages-Text'] =
            'Here you can see all the languages that I have acquired during my projects. The distribution is just an illustration of how familiar I am with each language in relation to other languages.';
        curLang['Project-Images'] = 'Pictures';
    }

    return curLang;
}
