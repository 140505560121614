import React, {useState, useRef} from 'react';
import anime from 'animejs';
import './ProgressBar.css';


function ProgressBar(props) {

  //const [isNavbarOpen, setNavbarOpen] = useState(false);
  //const [isDarkmode, setDarkmode] = useState(true);
  
  //let isNavbarShrinkedBool = useRef(false);

  React.useEffect(() => {

  }, []);

  return (
    <div className="ProgressBarBox" style={
        {"width":props.width, "height":props.height + "px", "borderRadius":(props.height/2) + "px"}
    }>
        <div className="ProgressBar" style={
            {"width":"100%", "height":"100%", "transform":"translateX(-" + ((1-props.percent)*100.0) + "%)"}
        }></div>
    </div>
  );
}

export default ProgressBar;
