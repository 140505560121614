import React, { useState, useRef, useContext } from 'react';
import anime from 'animejs';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { langContext, returnLang } from './lang';

import de_flag from './de.svg';
import us_flag from './us.svg';
import light_flag from './light.svg';
import dark_flag from './dark.svg';

function addClassesWithQuerySelectorAll(query, myyClass) {
    var elems = document.querySelectorAll(query);
    var index = 0,
        length = elems.length;
    for (; index < length; index++) {
        elems[index].classList.add(myyClass);
    }
}

function removeClassesWithQuerySelectorAll(query, myClass) {
    var elems = document.querySelectorAll(query);
    var index = 0,
        length = elems.length;
    for (; index < length; index++) {
        elems[index].classList.remove(myClass);
    }
}

function Navbar() {
    const [isNavbarOpen, setNavbarOpen] = useState(false);
    const [isDarkmode, setDarkmode] = useState(true);
    const { curLang, setLang } = useContext(langContext);

    let isNavbarShrinkedBool = useRef(false);

    React.useEffect(() => {
        if (isDarkmode) {
            document.body.removeAttribute('data-theme');
        } else {
            document.body.setAttribute('data-theme', 'light');
        }
    }, [isDarkmode]);

    React.useEffect(() => {
        function handleResize() {
            //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
        }

        const handleScroll = () => {
            if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
                if (!isNavbarShrinkedBool.current) {
                    document.querySelector('#navbar').classList.add('navbarShrink');
                    addClassesWithQuerySelectorAll('.navTab', 'navTabShrink');

                    anime({
                        targets: '#navbarSymbol',
                        top: 8,
                        right: 2,
                        scale: 0.8,
                        duration: 400,
                        easing: 'cubicBezier(.5,.01,.18,.98)',
                    });

                    isNavbarShrinkedBool.current = true;
                    //setNavbarShrinked(true);
                }
            } else {
                if (isNavbarShrinkedBool.current) {
                    document.querySelector('#navbar').classList.remove('navbarShrink');
                    removeClassesWithQuerySelectorAll('#navbar .navTab', 'navTabShrink');

                    anime({
                        targets: '#navbarSymbol',
                        top: 28,
                        right: 28,
                        scale: 1,
                        duration: 400,
                        easing: 'cubicBezier(.5,.01,.18,.98)',
                    });

                    isNavbarShrinkedBool.current = false;
                    //setNavbarShrinked(false);
                }
            }
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        //animationRef.current =

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    /*const openNavbar = () => {
    if(!isNavbarOpen) {

      setNavbarOpen(!isNavbarOpen);
      updateNavbar();
    }
  };*/

    const closeNavbar = () => {
        if (isNavbarOpen) {
            setNavbarOpen(!isNavbarOpen);
            updateNavbar();
        }
    };

    const toggleNavbar = () => {
        setNavbarOpen(!isNavbarOpen);
        updateNavbar();
    };

    const updateNavbar = () => {
        //anime.remove("#navbar");

        if (!isNavbarOpen) {
            document.querySelector('body').style.overflow = 'hidden';

            anime.set('#navbar', {
                height: '100vh',
            });

            anime({
                targets: ['#navbar .navTab'],
                translateX: ['-100vw', 0],
                duration: 300,
                delay: anime.stagger(100),
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });
            anime({
                targets: ['#navbar .langTab img'],
                translateX: ['-100vw', 0],
                duration: 300,
                delay: anime.stagger(100, { start: 400 }),
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });

            anime({
                targets: '#navbarSymbol .bar1',
                translateY: 12,
                rotate: 45,
                duration: 300,
                delay: 200,
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });

            anime({
                targets: '#navbarSymbol .bar3',
                translateY: -12,
                rotate: -45,
                duration: 300,
                delay: 200,
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });

            anime({
                targets: '#navbarSymbol .bar2',
                width: 56,
                height: 56,
                borderRadius: '5px',
                backgroundColor: 'rgba(20,20,20,0.4)',
                border: '1px solid #fff',
                duration: 600,
                delay: 0,
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });
        } else {
            document.querySelector('body').style.overflow = '';
            document.querySelector('#navbar').style.height = '';

            anime({
                targets: '#navbar .navTab',
                translateX: '100vw',
                duration: 400,
                delay: anime.stagger(50),
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });

            anime({
                targets: '#navbar .langTab img',
                translateX: '100vw',
                duration: 400,
                delay: anime.stagger(50, { start: 200 }),
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });

            anime({
                targets: '#navbarSymbol .bar1',
                translateY: 0,
                rotate: 0,
                duration: 300,
                delay: 300,
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });

            anime({
                targets: '#navbarSymbol .bar3',
                translateY: 0,
                rotate: 0,
                duration: 300,
                delay: 300,
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });

            anime({
                targets: '#navbarSymbol .bar2',
                width: 30,
                height: 4,
                borderRadius: '4px',
                backgroundColor: 'rgba(255,255,255,1)',
                border: '0px solid #fff',
                duration: 600,
                easing: 'cubicBezier(.5,.01,.18,.98)',
            });
        }
    };

    return (
        <div id="navbar" className="navbar">
            <div className="container1">
                <span id="navbarSymbol" onClick={toggleNavbar}>
                    <i className="bar bar1"></i>
                    <i className="bar bar2"></i>
                    <i className="bar bar3"></i>
                </span>
                <div className="container1-1">
                    <div className="container2-1">
                        <Link className="navTab firstTab" to="/" onClick={closeNavbar}>
                            {curLang['Navbar_about']}
                        </Link>
                        <Link className="navTab" to="/projects" onClick={closeNavbar}>
                            {curLang['Navbar_projects']}
                        </Link>
                        <Link className="navTab" to="/contact" onClick={closeNavbar}>
                            {curLang['Navbar_contact']}
                        </Link>

                        <div className="langTab lastTab">
                            <img
                                src={de_flag}
                                alt=""
                                onClick={() => {
                                    setLang(returnLang('de'));
                                }}
                            />
                            <img
                                src={us_flag}
                                alt=""
                                onClick={() => {
                                    setLang(returnLang('en'));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
