import React, { useState, useRef, useCallback } from 'react';
import './MyImage.css';
import anime from 'animejs';

var imgsTotal = 0;
var imgsLoaded = 0;
//const [count, setImageCount] = useState(0);
//const ExampleContext = React.createContext(0);

export function getImagesTotal() {
    return imgsTotal;
}

export function getImagesLoaded() {
    return imgsLoaded;
}

function isInViewport(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    var elemLeft = rect.left;
    var elemRight = rect.right;

    var isVisible =
        ((elemTop >= 0 && elemTop <= window.innerHeight) || (elemBottom >= 0 && elemBottom <= window.innerHeight)) &&
        ((elemLeft >= 0 && elemLeft <= window.innerWidth) || (elemRight >= 0 && elemRight <= window.innerWidth));
    return isVisible;
}

function MyImage(props) {
    //const value = React.useContext(ExampleContext);
    const [isImgMiniLoaded, setImgMiniLoaded] = useState(false);
    const [isImgLoaded, setImgLoaded] = useState(false);
    const [isImgLoading, setImgLoading] = useState(false);

    const [imgSize, setImgSize] = useState({ w: props.w, h: props.h });

    const [iconSize, setIconSize] = useState(50);

    //const [ImgSrc, setImgSrc] = useState(props.srcMinimal);
    let myImageMainDiv = useRef(null);
    let myImageMinimalImg = useRef(null);
    let myImageLoadDiv = useRef(null);
    let img = useRef(null);

    React.useEffect(() => {
        imgsTotal++;

        return () => {};
    }, []);

    React.useEffect(() => {
        if (imgSize.h === undefined) setImgSize({ w: props.w, h: myImageMinimalImg.current.height });
        else if (imgSize.w === undefined) setImgSize({ w: myImageMinimalImg.current.width, h: props.h });

        function loadHDImage() {
            if (isImgLoading) return;

            setImgLoading(true);

            if (!props.srcHD) return;

            img.current = new Image();

            function doneLoad() {
                setImgLoaded(true);

                anime.set(myImageLoadDiv.current, {
                    translateX: '-50%',
                    translateY: '-50%',
                });

                anime({
                    targets: myImageLoadDiv.current,
                    scale: [1.0, 0.0],
                    opacity: [1.0, 0.0],
                    duration: 500,
                    easing: 'cubicBezier(.5,.01,.18,.98)',
                    complete: () => {
                        if (!myImageLoadDiv.current) return;

                        myImageLoadDiv.current.remove();
                    },
                });
            }

            img.current.onload = function () {
                //myImageMainDiv.current.appendChild(img);
                if (!myImageMinimalImg.current) return;
                if (!myImageLoadDiv.current) return;

                myImageMainDiv.current.insertBefore(img.current, myImageMinimalImg.current);

                doneLoad();

                myImageMinimalImg.current.style.pointerEvents = 'none';

                anime({
                    targets: myImageMinimalImg.current,
                    opacity: 0.0,
                    duration: 1000,
                    easing: 'cubicBezier(.5,.01,.18,.98)',
                    complete: () => {
                        if (myImageMinimalImg && myImageMinimalImg.current) {
                            myImageMinimalImg.current.remove();
                            myImageMinimalImg.current = null;
                        }
                    },
                });
            };

            img.current.onerror = function () {
                //myImageMainDiv.current.appendChild(img);
                doneLoad();
            };

            img.current.src = props.srcHD;
        }

        function tryToLoadHDImage() {
            if (myImageMainDiv.current) {
                if (props.forceLoad) {
                    loadHDImage();
                    return;
                }
                let isVisible = true;

                let el = myImageMainDiv.current;

                while (el.parentNode) {
                    el = el.parentNode;
                    if (el.parentNode && window.getComputedStyle(el).display === 'none') {
                        isVisible = false;
                        break;
                    }
                }

                if (isVisible && isInViewport(myImageMainDiv.current)) {
                    loadHDImage();
                }
            }
        }

        function handleScroll() {
            tryToLoadHDImage();
        }

        if (props.srcHD && !isImgLoading) {
            setTimeout(handleScroll, 50);
            window.addEventListener('scroll', handleScroll);
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isImgLoading, props.srcHD]);

    function scaleImageDiv(_w, _h) {
        let ratio = 0.0;
        let width = 0;
        let height = 0;

        if (!myImageMainDiv.current) return;

        if (myImageMinimalImg.current) {
            width = myImageMinimalImg.current.naturalWidth;
            height = myImageMinimalImg.current.naturalHeight;
        } else if (img.current) {
            width = img.current.naturalWidth;
            height = img.current.naturalHeight;
        } else {
            return;
        }

        if (_w !== 0 && _h === 0) {
            ratio = _w / width; // get ratio for scaling image

            myImageMainDiv.current.style.width = _w + 'px';
            myImageMainDiv.current.style.height = height * ratio + 'px';
            height = height * ratio; // Reset height to match scaled image
            width = width * ratio; // Reset width to match scaled image
        }

        // Check if current height is larger than max
        if (_h !== 0 && _w === 0) {
            ratio = _h / height; // get ratio for scaling image
            myImageMainDiv.current.style.height = _h + 'px';
            myImageMainDiv.current.style.width = width * ratio + 'px';
            width = width * ratio; // Reset width to match scaled image
            height = height * ratio; // Reset height to match scaled image
        }

        if (_h !== 0 && _w !== 0) {
            myImageMainDiv.current.style.width = _w + 'px';
            myImageMainDiv.current.style.height = _h + 'px';

            for (let i = 0; i < 2; i++) {
                let obj;
                if (i === 0) {
                    obj = myImageMinimalImg;

                    if (!obj.current) {
                        continue;
                    }

                    width = obj.current.naturalWidth;
                    height = obj.current.naturalHeight;
                } else if (i === 1) {
                    obj = img;
                    if (!obj.current) continue;

                    width = obj.current.naturalWidth;
                    height = obj.current.naturalHeight;
                }

                // if(width > _w) {
                ratio = _w / width; // get ratio for scaling image

                obj.current.style.width = _w + 'px';
                obj.current.style.height = height * ratio + 'px';
                height = height * ratio; // Reset height to match scaled image
                width = width * ratio; // Reset width to match scaled image
                //}

                if (height < _h) {
                    ratio = _h / height; // get ratio for scaling image

                    obj.current.style.height = _h + 'px';
                    obj.current.style.width = width * ratio + 'px';
                    height = height * ratio; // Reset height to match scaled image
                    width = width * ratio; // Reset width to match scaled image
                }
            }
        }

        if (myImageLoadDiv.current) {
            //myImageLoadDiv.current.style.top = "100px";
            //myImageLoadDiv.current.style.left = "100px";
        }
    }

    const handleResize = useCallback(() => {
        if (imgSize.w && imgSize.h) {
            let w = imgSize.w;
            let h = imgSize.h;
            if (props.autoscaleMargin !== null) {
                if (document.documentElement.clientWidth < imgSize.w + props.autoscaleMargin) w = document.documentElement.clientWidth - props.autoscaleMargin;

                if (window.innerHeight < imgSize.h + props.autoscaleMargin) h = window.innerHeight - props.autoscaleMargin;

                //scaleImageDiv(imgSize.w, imgSize.h);
            }
            //scaleImageDiv(document.documentElement.clientWidth - props.autoscaleMargin, window.innerHeight - props.autoscaleMargin);

            scaleImageDiv(w, h);
        } else if (imgSize.w) {
            if (props.autoscaleMargin === null || document.documentElement.clientWidth > imgSize.w + props.autoscaleMargin) {
                scaleImageDiv(imgSize.w, 0);
            } else scaleImageDiv(document.documentElement.clientWidth - props.autoscaleMargin, 0);
        } else {
            if (props.autoscaleMargin === null || window.innerHeight > imgSize.h + props.autoscaleMargin) {
                scaleImageDiv(0, imgSize.h);
            } else scaleImageDiv(0, window.innerHeight - props.autoscaleMargin);
        }
    }, [imgSize.w, imgSize.h, props.autoscaleMargin]);

    React.useEffect(() => {
        if (imgSize.w || imgSize.h) {
            handleResize();
            setTimeout(handleResize, 10);
            window.addEventListener('resize', handleResize);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [imgSize.w, imgSize.h, props.autoscaleMargin, isImgMiniLoaded, isImgLoaded, handleResize]);

    React.useEffect(() => {
        if (myImageLoadDiv.current) {
            myImageLoadDiv.current.style.width = iconSize + 'px';
            myImageLoadDiv.current.style.height = iconSize + 'px';
        }
    });

    function imageMinimalLoadedEvent() {
        imgsLoaded++;
        setImgMiniLoaded(true);
        handleResize();

        if (props.srcHD) {
            myImageMinimalImg.current.style.filter = 'blur(4px)';
        }
    }

    function imageMinimalErrorEvent() {
        imgsLoaded++;
        setImgMiniLoaded(true);
        handleResize();
        console.log('Minimal image ERROR: ' + imgsLoaded + ' / ' + imgsTotal);
    }

    return (
        <div
            className={(props.className ? props.className + ' ' : '') + 'MyImage'}
            style={{
                width: imgSize.w,
                height: imgSize.h,
            }}
            ref={myImageMainDiv}
            onClick={props.onClick}
        >
            <img className="MyImageMinimal" onLoad={imageMinimalLoadedEvent} onError={imageMinimalErrorEvent} ref={myImageMinimalImg} alt="" src={props.srcMinimal}></img>
            {props.srcHD ? (
                <div className="MyImageLoadingIcon" ref={myImageLoadDiv}>
                    <div className="MyImage_loadingCircle"></div>
                </div>
            ) : null}
        </div>
    );
}
//filter: blur(8px);

export default MyImage;
